import { Injectable, inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as jose from 'jose';
import { delay, from, of, switchMap, tap, type Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import type { JwtClaims } from '../models/jwt-claims.model';

enum AdminRole {
  /** @deprecated */
  AppAdmin = 'app_admin',
  SuperAdmin = 'super_admin',
  FunGamesAdmin = 'fun_games_admin',
  EduEquAdmin = 'edu_equ_admin',
  InventoryAdmin = 'inventory_admin',
  PeopleAdmin = 'people_admin',
  PhoneNumbersAdmin = 'phone_numbers_admin',
  BankCardsAdmin = 'bank_cards_admin',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly oauthService = inject(OAuthService);

  initialize$(): Observable<void> {
    this.oauthService.configure({
      issuer: environment.auth.issuer,
      clientId: environment.auth.clientId,
      responseType: 'code',
      scope: 'openid email roles profile',
      redirectUri: environment.auth.redirectUri,
      postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
    });
    this.oauthService.setupAutomaticSilentRefresh();
    return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      tap(() => {
        // On redirectUri OAuthService consumes code and fetches token. After that it's no longer needed
        if (!window.location.href.startsWith(environment.auth.redirectUri)) return;

        const preLoginPath = decodeURIComponent(this.oauthService.state ?? '');
        const preLoginUri = `${document.baseURI}${preLoginPath}`;
        const href = preLoginUri.startsWith(environment.auth.redirectUri)
          ? document.baseURI
          : preLoginUri;
        window.location.href = href;
      }),
      switchMap(() => {
        // If user is not logged in, immediately redirect to login page.
        // Delay is needed to prevent page flashing before redirect because initLoginFlow takes some time
        const time = !this.isLoggedIn() ? 10000 : 0;
        return of(undefined).pipe(delay(time));
      }),
    );
  }

  login(): void {
    const preLoginPath = window.location.href.replace(document.baseURI, '');
    return this.oauthService.initLoginFlow(preLoginPath);
  }

  logout(): void {
    return this.oauthService.logOut();
  }

  isLoggedIn(): boolean {
    const isLoggedIn = this.oauthService.hasValidAccessToken();
    if (!isLoggedIn) this.login();
    return isLoggedIn;
  }

  isSuperAdmin(): boolean {
    return this.hasRole(AdminRole.AppAdmin) || this.hasRole(AdminRole.SuperAdmin);
  }

  isFunGamesAdmin(): boolean {
    return this.isSuperAdmin() || this.hasRole(AdminRole.FunGamesAdmin);
  }

  isEduEquAdmin(): boolean {
    return this.isSuperAdmin() || this.hasRole(AdminRole.EduEquAdmin);
  }

  isInventoryAdmin(): boolean {
    return this.isSuperAdmin() || this.hasRole(AdminRole.InventoryAdmin);
  }

  isPeopleAdmin(): boolean {
    return this.isSuperAdmin() || this.hasRole(AdminRole.PeopleAdmin);
  }

  isPhoneNumbersAdmin(): boolean {
    return this.isSuperAdmin() || this.hasRole(AdminRole.PhoneNumbersAdmin);
  }

  isBankCardsAdmin(): boolean {
    return this.isSuperAdmin() || this.hasRole(AdminRole.BankCardsAdmin);
  }

  isNotAdmin(): boolean {
    return !Object.values(AdminRole).some((role) => this.hasRole(role));
  }

  private hasRole(role: AdminRole): boolean {
    if (!this.isLoggedIn()) return false;

    const claims = this.getJwtClaims();
    return claims.realm_access.roles.includes(role);
  }

  getToken(): string {
    return this.oauthService.getAccessToken();
  }

  private getJwtClaims(): JwtClaims {
    const token = this.getToken();
    const claims = jose.decodeJwt<JwtClaims>(token);
    return claims;
  }
}
