import { inject, Pipe, type PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import type { NavGroup, NavLink } from './navbar.models';

@Pipe({
  name: 'opaFilterNavLink',
  standalone: true,
})
export class FilterNavLinkPipe implements PipeTransform {
  private readonly authService = inject(AuthService);
  private readonly isDev = !environment.production;

  transform(nav: NavGroup | NavLink): boolean {
    if (nav.devOnly && !this.isDev) return false;
    if (nav.superAdmin && !this.authService.isSuperAdmin()) return false;
    if (nav.funGamesAdmin && !this.authService.isFunGamesAdmin()) return false;
    if (nav.eduEquAdmin && !this.authService.isEduEquAdmin()) return false;
    if (nav.inventoryAdmin && !this.authService.isInventoryAdmin()) return false;
    if (nav.peopleAdmin && !this.authService.isPeopleAdmin()) return false;
    if (nav.phoneNumbersAdmin && !this.authService.isPhoneNumbersAdmin()) return false;
    if (nav.bankCardsAdmin && !this.authService.isBankCardsAdmin()) return false;
    return true;
  }
}
