<p-modal
  [heading]="'Edit ' + (user ? user.firstName + ' ' + user.lastName : 'Member')"
  [open]="open"
  (dismiss)="close()"
>
  <form
    *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner"
    class="form"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        [type]="'button'"
        (click)="reloadData()"
      >
        Reload Edit Member
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <div class="form-controls">
        <p-button
          class="reset-password"
          [icon]="'none'"
          [loading]="data.resettingPassword"
          [type]="'button'"
          [variant]="'secondary'"
          (click)="resetPassword()"
        >
          Send password reset link
        </p-button>

        <div class="switch-control">
          <p-text>User Status</p-text>
          <p-switch
            *ngrxLet="form.controls.active as control"
            [checked]="control.value ?? undefined"
            [disabled]="control.disabled"
            (update)="control.patchValue($event.detail.checked)"
          >
            Active
          </p-switch>
        </div>

        <p-select-wrapper
          *ngrxLet="form.controls.position as control"
          [filter]="true"
          [label]="'Position'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <select [attr.required]="true" [formControl]="control">
            <option
              *ngFor="let position of data.positions; trackBy: trackPosition"
              [value]="position.id"
            >
              {{ position.name }}
            </option>
          </select>
        </p-select-wrapper>

        <div class="checkbox-control">
          <p-text>Increase Fun & Games Budget</p-text>
          <p-checkbox-wrapper
            *ngrxLet="form.controls.doubleMonthlyBudget as control"
            [label]="'Add additional €20 per month'"
            [message]="control.errors?.['message'] ?? ''"
            [state]="control.touched && control.errors ? 'error' : 'none'"
          >
            <input type="checkbox" [formControl]="control" />
          </p-checkbox-wrapper>
        </div>
      </div>

      <p-button-group class="modal-actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Save
        </p-button>
        <p-button
          [iconSource]="'assets/icons/close.svg'"
          [type]="'button'"
          [variant]="'secondary'"
          (click)="close()"
        >
          Close
        </p-button>
      </p-button-group>
    </ng-container>
  </form>
</p-modal>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
