import type { NumberComparator } from '../number-comparator.model';
import type { InventoryLocation } from './inventory-location.model';
import type { InventoryManufacturer } from './inventory-manufacturer.model';
import type { InventoryOwner } from './inventory-owner.model';
import type { InventoryService } from './inventory-service.model';
import type { InventoryStatus } from './inventory-status.model';
import type { InventoryType } from './inventory-type.model';
import type { User } from './user.model';

export interface InventoryItem {
  id: number;
  manufacturer: InventoryManufacturer;
  model: string;
  specification: string;
  type: InventoryType;
  serialNumber: string;
  imei: string;
  owner: InventoryOwner;
  location: InventoryLocation;
  warrantyExpiration: string;
  contractNumber: string;
  price: number;
  assignee?: User;
  note: string;
  status: InventoryStatus;
  services: InventoryService[];
  s3ReceiptFileKey?: string;
  s3WarrantyFileKey?: string;
}

export interface InventoryItemFilter {
  [InventoryFilters.manufacturerId]?: number;
  [InventoryFilters.model]?: string;
  [InventoryFilters.specification]?: string;
  [InventoryFilters.typeId]?: number;
  [InventoryFilters.serialNumber]?: string;
  [InventoryFilters.imei]?: string;
  [InventoryFilters.ownerId]?: number;
  [InventoryFilters.locationId]?: number;
  [InventoryFilters.warrantyExpirationFrom]?: string;
  [InventoryFilters.warrantyExpirationTo]?: string;
  [InventoryFilters.contractNumber]?: string;
  [InventoryFilters.price]?: number;
  [InventoryFilters.priceComparisonType]?: NumberComparator;
  [InventoryFilters.assigneeId]?: number;
  [InventoryFilters.statusId]?: number;
  [InventoryFilters.note]?: string;
}

export interface UnassignInventoryItem {
  inventoryItemId: number;
  locationId: number;
}

export interface AssignInventoryItem {
  inventoryItemId: number;
  assigneeId: number;
}

export interface CreateOrEditInventoryItem {
  warranty: File | null;
  receipt: File | null;
  command: CreateOrEditInventoryCommand;
}

export interface BulkCreateInventoryItems {
  import: File;
  warranty: File | null;
  receipt: File | null;
}

export interface CreateOrEditInventoryCommand {
  manufacturerId: number;
  model: string;
  specification: string;
  typeId: number;
  serialNumber: string;
  imei: string | null;
  ownerId: number;
  locationId: number;
  warrantyExpiration: string;
  contractNumber: string | null;
  price: number;
  assigneeId: number | null;
  note: string | null;
  statusId: number;
}

export const InventoryFilters = {
  manufacturerId: 'manufacturerId',
  model: 'model',
  specification: 'specification',
  typeId: 'typeId',
  serialNumber: 'serialNumber',
  imei: 'imei',
  ownerId: 'ownerId',
  locationId: 'locationId',
  warrantyExpirationFrom: 'warrantyExpirationFrom',
  warrantyExpirationTo: 'warrantyExpirationTo',
  contractNumber: 'contractNumber',
  price: 'price',
  priceComparisonType: 'priceComparisonType',
  assigneeId: 'assigneeId',
  statusId: 'statusId',
  note: 'note',
} as const;

export type InventoryFilter = keyof typeof InventoryFilters;
