<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Events</p-heading>

  <p-link
    *ngIf="isFunGamesAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/events/create'">Create New Event</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="list">
  <p-heading *ngrxLet="events$ | async as events" [size]="'small'" [tag]="'h4'">
    <div class="list-heading">
      <span>{{ events?.totalElements }} Events</span>

      <p-button-pure
        *ngIf="events"
        [iconSource]="'assets/icons/reset.svg'"
        [underline]="true"
        (click)="resetFilters()"
      >
        Reset Filters
      </p-button-pure>
    </div>
  </p-heading>

  <ng-container *ngrxLet="events$ as events; error as eventsError; suspenseTpl: spinner">
    <div *ngIf="eventsError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadEvents()"
      >
        Reload Events
      </p-button-pure>
    </div>

    <ng-container *ngIf="!eventsError && events">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Event list table">
          <p-table-head *ngrxLet="sort$ as activeSort">
            <p-table-head-row>
              <p-table-head-cell
                *ngrxLet="{
                  label: 'Event Name',
                  control: form.controls.eventName,
                  sortId: 'name'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="cell.label">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Vendor',
                  control: form.controls.vendorName,
                  sortId: 'vendor.name'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="cell.label">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Event Type',
                  control: form.controls.eventType,
                  sortId: 'eventType.name'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper [native]="true" [label]="cell.label">
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All events</option>
                      <option *ngFor="let type of eventTypes" [value]="type">
                        {{ eventTypeTranslation[type] | transloco }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Participants',
                  participantCountComparator: form.controls.participantCountComparator,
                  participantCount: form.controls.participantCount
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                <opa-table-filter [active]="cell.participantCount.value != null">
                  <p-select-wrapper [native]="true" [label]="'Comparator'">
                    <select [formControl]="cell.participantCountComparator">
                      <option *ngFor="let comparator of numberComparators" [value]="comparator">
                        {{ numberComparatorTranslation[comparator] | transloco }}
                      </option>
                    </select>
                  </p-select-wrapper>

                  <p-text-field-wrapper [label]="cell.label">
                    <input type="number" [formControl]="cell.participantCount" />
                  </p-text-field-wrapper>
                </opa-table-filter>
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Event Date',
                  controlFrom: form.controls.dateFrom,
                  controlTo: form.controls.dateTo,
                  sortId: 'date'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.controlFrom.value || !!cell.controlTo.value">
                  <p-text-field-wrapper [label]="cell.label + ' From'">
                    <input type="date" [formControl]="cell.controlFrom" />
                  </p-text-field-wrapper>

                  <p-text-field-wrapper [label]="cell.label + ' To'">
                    <input type="date" [formControl]="cell.controlTo" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let event of events.content | fillArray: events.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="event; else emptyCell">
                  <a [routerLink]="['/events', event.id]">{{ event.name }}</a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="event; else emptyCell">
                  {{ event.vendorName }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="event; else emptyCell">
                  {{ eventTypeTranslation[event.type] | transloco }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="event; else emptyCell">
                  {{ event.participants.length }} people
                  <p-popover [direction]="'right'">
                    <div class="popover-content">
                      <p-text *ngFor="let user of event.participants; trackBy: trackUser">
                        {{ user.firstName }} {{ user.lastName }}
                      </p-text>
                    </div>
                  </p-popover>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="event; else emptyCell">
                  {{ event.date | date: 'dd.MM.yyyy.' }}
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="events.totalElements"
        [itemsPerPage]="events.size"
        [activePage]="events.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
