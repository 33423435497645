import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  tap,
  type Observable,
} from 'rxjs';
import { UserIconComponent } from '../../shared/components/user-icon/user-icon.component';
import { BudgetType } from '../../shared/models/api/edu-expense.model';
import {
  EduTransactionLogType,
  type EduTransactionLogForMonth,
  type EduTransactionLogForYear,
  type EduTransactionLogRecord,
} from '../../shared/models/api/edu-transaction-log.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { OrderByPipe } from '../../shared/pipes/order-by.pipe';
import { UserService } from '../../shared/services/api/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { budgetTypeTranslation } from '../edu-expense-create/edu-expense-create.helpers';
import { EduBudgetTransactionFilterPipe } from './edu-budget-transaction-filter.pipe';
import { transactionLogTypeTranslation } from './edu-budget.helpers';
import type { EduBudgetFilterFormValue, EduTransactionLogData } from './edu-budget.models';

@Component({
  selector: 'opa-edu-budget',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    UserIconComponent,
    LetDirective,
    OrderByPipe,
    ReactiveFormsModule,
    TranslocoModule,
    LeadingPlusPipe,
    EduBudgetTransactionFilterPipe,
  ],
  templateUrl: './edu-budget.component.html',
  styleUrl: './edu-budget.component.scss',
})
export class EduBudgetComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  readonly TransactionLogType = EduTransactionLogType;
  readonly logTypeTranslation = transactionLogTypeTranslation;
  readonly budgetTypes = Object.values(BudgetType);
  readonly budgetTypeTranslation = budgetTypeTranslation;

  readonly isEduEquAdmin = inject(AuthService).isEduEquAdmin();
  readonly now = new Date();
  readonly form = this.buildForm();
  me$ = this.getMe$();
  transactionLogData$ = this.getTransactionLogData$();

  reloadMe(): void {
    this.me$ = this.getMe$();
  }

  reloadTransactionLogData(): void {
    this.transactionLogData$ = this.getTransactionLogData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  trackYearLog(index: number, log: EduTransactionLogForYear): number {
    return log.year;
  }

  trackMonthLog(index: number, log: EduTransactionLogForMonth): string {
    return log.monthName;
  }

  trackLog(index: number, log: EduTransactionLogRecord): number {
    return log.id!;
  }

  private getMe$(): Observable<User> {
    return this.userService.getMe().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  private getTransactionLogData$(): Observable<EduTransactionLogData> {
    const activeYears$ = this.userService.getMyActiveYears().pipe(
      tap((activeYears) => {
        if (!this.form.controls.activeYear.value && activeYears.length > 0)
          this.form.controls.activeYear.patchValue(`${activeYears[0]}`);
      }),
    );

    const transactionLogs$ = combineLatest([
      this.form.controls.activeYear.valueChanges.pipe(
        startWith(this.form.controls.activeYear.value),
        distinctUntilChanged(),
      ),
      this.userService.getMyEduTransactionLog(),
    ]).pipe(map(([year, log]) => log.records.filter((record) => `${record.year}` === year)));

    return combineLatest([activeYears$, transactionLogs$]).pipe(
      map(([activeYears, transactionLogs]) => ({ activeYears, transactionLogs })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private buildForm(): FormGroup<ControlsOf<EduBudgetFilterFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<EduBudgetFilterFormValue>>({
      activeYear: this.formBuilder.nonNullable.control<string | undefined>(undefined),
      budgetType: this.formBuilder.nonNullable.control<BudgetType | null>(null),
    });

    return form;
  }
}
