<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory</p-heading>

  <p-link
    *ngIf="isInventoryAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/inventory-items/create'">Create New Item</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div *ngrxLet="inventoryItem$ | async as inventoryItem" class="breadcrumbs">
    <div class="name">
      <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
        <a [routerLink]="isInventoryAdmin ? '/inventory-items' : '/my-inventory-items'">
          Inventory
        </a>
      </p-link-pure>

      <p-heading [size]="'large'" [tag]="'h4'">
        @if (!inventoryItem) {
          &nbsp;
        } @else {
          {{ inventoryItem.manufacturer.description }} {{ inventoryItem.model }}
        }
      </p-heading>
    </div>

    <ng-container *ngIf="isInventoryAdmin && inventoryItem">
      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-link-pure [iconSource]="'assets/icons/edit.svg'" [size]="'x-small'" [underline]="true">
        <a [routerLink]="['/inventory-items', inventoryItem.id, 'edit']">Edit Item</a>
      </p-link-pure>
    </ng-container>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Inventory Item Details</span>
    </div>
  </p-heading>

  <ng-container
    *ngrxLet="inventoryItem$ as inventoryItem; error as inventoryItemError; suspenseTpl: spinner"
  >
    <div *ngIf="inventoryItemError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadInventoryItem()"
      >
        Reload Inventory Item Details
      </p-button-pure>
    </div>

    <div *ngIf="!inventoryItemError && inventoryItem" class="details">
      <div>
        <p-text [weight]="'semi-bold'">Location:</p-text>
        <p-text>{{ inventoryItem.location.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Assignee:</p-text>
        <p-text>
          {{ inventoryItem.assignee?.firstName || '-' }} {{ inventoryItem.assignee?.lastName }}
        </p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Manufacturer</p-text>
        <p-text>{{ inventoryItem.manufacturer.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Model:</p-text>
        <p-text>{{ inventoryItem.model }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Specification:</p-text>
        <p-text>{{ inventoryItem.specification || '-' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Description:</p-text>
        <p-text>{{ inventoryItem.type.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Serial Number:</p-text>
        <p-text>{{ inventoryItem.serialNumber || '-' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">IMEI:</p-text>
        <p-text>
          {{ inventoryItem.imei || '-' }}
        </p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Owner:</p-text>
        <p-text>{{ inventoryItem.owner.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Warranty Expiration:</p-text>
        <p-text>{{ inventoryItem.warrantyExpiration | date: 'dd.MM.yyyy' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Contract number:</p-text>
        <p-text>{{ inventoryItem.contractNumber || '-' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Price:</p-text>
        <p-text>{{ inventoryItem.price | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Note:</p-text>
        <p-text>{{ inventoryItem.note || '-' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Status:</p-text>
        <p-text>{{ inventoryItem.status.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Services:</p-text>
        <p-text>{{ inventoryItem.services.length }}</p-text>
      </div>

      <div *ngIf="isInventoryAdmin">
        <p-text [weight]="'semi-bold'">Receipt:</p-text>

        <p-button-pure
          *ngIf="inventoryItem.s3ReceiptFileKey"
          [iconSource]="'assets/icons/download.svg'"
          [underline]="true"
          (click)="downloadReceipt(inventoryItem.id)"
        >
          Download Receipt
        </p-button-pure>

        <p-text *ngIf="!inventoryItem.s3ReceiptFileKey">{{ '-' }}</p-text>
      </div>

      <div *ngIf="isInventoryAdmin">
        <p-text [weight]="'semi-bold'">Warranty:</p-text>

        <p-button-pure
          *ngIf="inventoryItem.s3WarrantyFileKey"
          [iconSource]="'assets/icons/download.svg'"
          [underline]="true"
          (click)="downloadWarranty(inventoryItem.id)"
        >
          Download Warranty
        </p-button-pure>

        <p-text *ngIf="!inventoryItem.s3WarrantyFileKey">{{ '-' }}</p-text>
      </div>

      <div *ngIf="isInventoryAdmin">
        <opa-assign-inventory-item
          [inventoryItem]="inventoryItem"
          (assignSuccessful)="reloadInventoryItem()"
        />
        <opa-unassign-inventory-item
          [inventoryItem]="inventoryItem"
          (unassignSuccessful)="reloadInventoryItem()"
        />
      </div>
    </div>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
