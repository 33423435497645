import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { EduTransactionLog } from '../../models/api/edu-transaction-log.model';
import type { FunTransactionLog } from '../../models/api/fun-transaction-log.model';
import type { Page } from '../../models/api/page.model';
import type { CreateUser, EditUser, User } from '../../models/api/user.model';
import type { UsersStatusCount } from '../../models/api/users-status-count.model';
import type { SortQuery } from '../../models/sort-query.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly httpClient = inject(HttpClient);
  private readonly userApi = `${environment.apiUrl}/v1/users`;

  getMe(): Observable<User> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get My User Info');
    return this.httpClient.get<User>(`${this.userApi}/me`, { context });
  }

  getMyActiveYears(): Observable<number[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get My Active Years');
    return this.httpClient.get<number[]>(`${this.userApi}/my-active-years`, { context });
  }

  getMyFunTransactionLog(): Observable<FunTransactionLog> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get My Fun & Games Transaction Log',
    );
    return this.httpClient.get<FunTransactionLog>(`${this.userApi}/my-transactions-fun`, {
      context,
    });
  }

  getMyEduTransactionLog(): Observable<EduTransactionLog> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get My Edu & Equ Transaction Log',
    );
    return this.httpClient.get<EduTransactionLog>(`${this.userApi}/my-transactions-edu`, {
      context,
    });
  }

  getUsersStatusCount(): Observable<UsersStatusCount> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Users Status Count');
    return this.httpClient.get<UsersStatusCount>(`${this.userApi}/status-count`, { context });
  }

  getActiveUsers(): Observable<User[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Active Users');
    return this.httpClient.get<User[]>(`${this.userApi}/active`, { context });
  }

  getUsers(pageNo: number | null, name?: string, sort?: SortQuery[]): Observable<Page<User>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Users');

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (name != null) params = params.set('name', name);
    if (sort != null && sort.length > 0)
      params = params.set('sort', sort.map((s) => `${s.property},${s.direction}`).join(','));

    return this.httpClient.get<Page<User>>(this.userApi, { context, params });
  }

  getUser(userId: number): Observable<User> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get User');
    return this.httpClient.get<User>(`${this.userApi}/${userId}`, { context });
  }

  createUser(user: CreateUser): Observable<User> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create User');
    return this.httpClient.post<User>(this.userApi, user, { context });
  }

  editUser(userId: number, user: EditUser): Observable<User> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit User');
    return this.httpClient.patch<User>(`${this.userApi}/${userId}`, user, { context });
  }

  resetUserPassword(userId: number): Observable<void> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Reset User Password');
    return this.httpClient.patch<void>(`${this.userApi}/${userId}/password-reset`, {}, { context });
  }
}
